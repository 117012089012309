<template>
  <div class="backcolor pb-2" style="min-height: 100vh">
    <v-toolbar class="elevation-1">
      <v-icon color="pink" class="text-h4">mdi-view-dashboard</v-icon>
      <v-toolbar-title class="ml-3">
        <div class="d-flex flex-column">
          <span
            class="font-weight-bold"
            :style="
              $vuetify.breakpoint.smAndUp
                ? 'letter-spacing: 0.5rem'
                : 'letter-spacing: 0.2rem'
            "
          >
            {{ $t('admin.admin_dashbord') }}
          </span>
          <span class="font-weight-medium" style="font-size: 1rem">
            {{ formatedDate }}
          </span>
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu
        ref="menu"
        v-model="dataPicker"
        :close-on-content-click="false"
        :return-value.sync="dateForFilter"
        transition="scroll-x-reverse-transition"
        offset-y
        left
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon class="mr-sm-2 mr-md-4">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <v-date-picker
          v-model="dateForFilter"
          @change="selectDate(dateForFilter)"
          type="month"
          no-title
          color="blue"
          :max="maxMonth"
          header-color="darkblue"
        >
        </v-date-picker>
      </v-menu>
    </v-toolbar>
    <div class="ma-4 ma-md-6">
      <v-row>
        <v-col cols="12" sm="6" md="3" v-for="(t, i) in totalCards" :key="i">
          <v-card class="rounded-lg" flat style="height: 100%">
            <div
              class="d-flex align-center justify-center"
              style="height: 100%"
            >
              <div style="width: 30%" class="text-center">
                <v-icon
                  size="40"
                  class="pa-3 rounded-circle"
                  :class="t.back"
                  :color="t.color"
                >
                  {{ t.icon }}
                </v-icon>
              </div>
              <div style="width: 70%" class="d-flex flex-column py-6">
                <span style="font-size: 1.2rem" class="font-weight-medium">
                  {{ t.name }}
                </span>
                <span style="font-size: 1.6rem" class="font-weight-bold">
                  {{ t.value }}
                </span>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="rounded-lg" flat style="height: 100%">
            <v-card-title>
              {{ $t('admin.admin_acessousuario') }}&nbsp; <b>{{ currentMonth }}</b>
            </v-card-title>
            <v-card-text>
              <ChartAccess :chartData="chartData" ref="userChart" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

import ChartAccess from "./Charts/ChartAccess.vue";

export default {
  components: {
    ChartAccess,
  },
  data() {
    return {
      formatedDate: "",
      currentMonth: "",
      chartData: { name: [], data: [[], [], []] },
      maxMonth: new Date().toISOString().substr(0, 7),
      dateForFilter: "",
      dataPicker: false,
      totalCards: [
        {
          icon: "mdi-account-group",
          name: this.$t('school.school_acesso'),
          value: 0,
          color: "light-blue accent-4",
          back: "blue-bg",
        },
        {
          icon: "mdi-school",
          name: this.$t('login.login_usuario.usuario4'),
          value: 0,
          color: "teal accent-3",
          back: "cyan-bg",
        },
        {
          icon: "mdi-human-male-board",
          name: this.$t('admin.admin_menu.menu10'),
          value: 0,
          color: "pink accent-2",
          back: "pink-bg",
        },
        {
          icon: "mdi-account-school",
          name: this.$t('admin.admin_menu.menu11'),
          value: 0,
          color: "amber accent-4",
          back: "yellow-bg",
        },
      ],
    };
  },
  created() {
    this.getDate(new Date());
  },
  methods: {
    getDate(date) {
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let year = date.getFullYear();
      this.formateDate(date.getMonth(), year);

      this.selectDate(year + "-" + month, year);
    },
    sumValues(accessNumbers) {
      let valuesArray = Object.values(accessNumbers);
      return valuesArray.reduce((acc, val) => acc + val, 0);
    },
    selectDate(date) {
      this.dateForFilter = date;

      const [year, month] = date.split("-");
      let monthStr = parseInt(month).toString();
      this.formateDate(monthStr, year);
      this.getChartData(date);
      setTimeout(() => {
        this.dataPicker = false;
      }, 100);
    },
    formateDate(month, year) {
      let monthNames = [
        { full: this.$t('admin.admin_calendario.Janeiro'), abb: this.$t('admin.admin_calendarioabb.Janeiro') },
        { full: this.$t('admin.admin_calendario.Fevereiro'), abb: this.$t('admin.admin_calendarioabb.Fevereiro') },
        { full: this.$t('admin.admin_calendario.Março'), abb: this.$t('admin.admin_calendarioabb.Março') },
        { full: this.$t('admin.admin_calendario.Abril'), abb: this.$t('admin.admin_calendarioabb.Abril') },
        { full: this.$t('admin.admin_calendario.Maio'), abb: this.$t('admin.admin_calendarioabb.Maio') },
        { full: this.$t('admin.admin_calendario.Junho'), abb: this.$t('admin.admin_calendarioabb.Junho') },
        { full: this.$t('admin.admin_calendario.Julho'), abb: this.$t('admin.admin_calendarioabb.Julho') },
        { full: this.$t('admin.admin_calendario.Agosto'), abb: this.$t('admin.admin_calendarioabb.Agosto') },
        { full: this.$t('admin.admin_calendario.Setembro'), abb: this.$t('admin.admin_calendarioabb.Setembro') },
        { full: this.$t('admin.admin_calendario.Outubro'), abb: this.$t('admin.admin_calendarioabb.Outubro') },
        { full: this.$t('admin.admin_calendario.Novembro'), abb: this.$t('admin.admin_calendarioabb.Novembro') },
        { full: this.$t('admin.admin_calendario.Dezembro'), abb: this.$t('admin.admin_calendarioabb.Dezembro') },
      ];
      this.currentMonth = monthNames[month - 1].full;
      this.formatedDate = this.currentMonth + ", " + year;
    },
    async getChartData(date) {
      try {
        let res = await axios.get(
          `${url}/access/userType/forManager/${date}`,
          authorization
        );

        let response = await this.filterDataByMonth(res.data.result, date);

        this.chartData = { name: [], data: [[], [], []] };
        for (let index = 0; index < response.length; index++) {
          const { school, school_count, student_count, teacher_count } =
            response[index];
          this.chartData.name.push(school);
          this.chartData.data[0].push(school_count);
          this.chartData.data[1].push(teacher_count);
          this.chartData.data[2].push(student_count);
        }

        this.getTotals(this.chartData.data);
        this.$refs.userChart.updateData();
      } catch (error) {
        console.error(error);
      }
    },
    filterDataByMonth(data, date) {
      return data.filter((obj) => obj.month === date);
    },
    getTotals(data) {
      const sums = data.map((arr) => this.sumValues(arr));
      this.totalCards[1].value = sums[0];
      this.totalCards[2].value = sums[1];
      this.totalCards[3].value = sums[2];
      this.totalCards[0].value = sums.reduce((acc, val) => acc + val);
    },
  },
};
</script>

<style scoped>
.pink-bg {
  background-color: #ff00ff2a;
}
.cyan-bg {
  background-color: #00ccff2a;
}
.yellow-bg {
  background-color: #ffe60031;
}
.blue-bg {
  background-color: #0000ff1c;
}
</style>
